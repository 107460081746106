<template>
  <div class="main-styles">
    <p>一般规则<br />1、如果比赛场地有变更，所有注单将被取消。
    </p>
    <p>
      2、NBA/NBL篮球赛事必须至少进行43分钟，投注的注单才被视为有效。任何其他的篮球联赛或比赛，至少要进行35分钟，投注的注单才被视为有效，除非另有注明。
    </p>
    <p>
      3、美国大学篮球联赛至少必须进行35分钟，投注的注单才被视为有效，除非另有注明。
    </p>
    <p>
      4、如果赛事是在上半场中断，所有上半场的注单将被取消。如果赛事是在下半场中断所有上半场的投注保持有效，但所有下半场的注单将被取消，除非在个别玩法规则另有注明。
    </p>
    <p>
      5、单节/半场的投注，比赛必须完成赛节注单才被视为有效，除非在个别玩法规则另有注明。
    </p>
    <p>
      6、美国大学篮球联赛场地规则：盘口指示的"主场"和"客场"信息仅供参考。无论原定场地是否更改为"主场"，"客场"或"中立场"，所有注单将保持有效。
    </p>
    <p>
      7、如比赛在法定时间提前进行，在比赛开始前的投注依然有效，在比赛开始后的所有投注均视为无效(滚球投注另作别论)。
    </p>
    <p>
      8、所有赛事或下半场的投注都包括加时赛，除非另有注明。第四节投注不包括加时赛。
    </p>
    <p>9、除非个别玩法规则另有注明，赛事完场时间将包括加时赛。</p>
    <p>
      <br />投注类型<br />独赢盘<br />1、预测哪一支球队将在比赛胜出。盘口提供两支球队为投注选项。
    </p>
    <p>2、赛事盘口包括全场、半场或单节投注。</p>
    <p>
      <br />让分盘<br />1、预测哪一支球队在盘口指定的让分球数在半场/全场/赛事单节赢得比赛。
    </p>
    <p>2、如果赛事在下半场取消或中断，所有上半场注单保持有效。</p>
    <p>3、如果赛事在下半场取消或中断，所有下半场注单将被取消。</p>
    <p>4、赛事盘口包括全场、半场或单节投注。</p>
    <p>
      <br />滚球让分盘<br />1、预测哪一支球队在盘口指定的让分数里赢得半场/全场/赛事单节的比赛。
    </p>
    <p>
      2、结算是以0-0的比分在比赛/时节结束后按盘口开出的让分球数做裁决。投注当时的比分对结算没有影响。
    </p>
    <p>3、赛事盘口包括全场、半场或单节投注。</p>
    <p><br />大/小盘（总比分）<br />1、赛事盘口包括全场、半场或单节投注。</p>
    <p>2、预测赛事总比分将大于或小于在盘口指定的大/小盘分数。</p>
    <p>
      3、如果赛事中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况，大/小盘注单才会被结算。若遇到任何其他情况，注单将一律被取消。
    </p>
    <p>
      4、如果赛事在上半场中断，所有上半场注单将被取消，除非中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况注单才会被结算。
    </p>
    <p>5、如果赛事在下半场取消或中断，所有上半场注单保持有效。</p>
    <p>
      6、如果赛事在下半场取消或中断，所有下半场注单将被取消，除非中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况注单才会被结算。
    </p>
    <p>
      7、如果赛事中断, 所有时节的注单将被取消除非遇到以下其中一个情况：<br />&nbsp;
      &nbsp;
      &nbsp;7.1、比赛中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况。<br />&nbsp;
      &nbsp; &nbsp;7.2、投注的时节是在比赛中断前。
    </p>
    <p>
      <br />滚球大/小盘（总比分）<br />1、如果赛事中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况，大/小盘注单才会被结算。若遇到任何其他情况，注单将一律被取消。
    </p>
    <p>
      2、结算是以0-0的比分在比赛/时节结束后按盘口开出的让分数做裁决。投注当时的比分对结算没有影响。
    </p>
    <p>3、预测赛事总比分将大于或小于在盘口指定的大/小盘分数。</p>
    <p>
      <br />大/小盘（主队或者客队的分数）<br />1、所有注单的结算都是依据相关体育机构的官方统计数据为准。
    </p>
    <p>2、预测赛事主队/客队的总分数将大于或小于在盘口指定的大/小盘分数。</p>
    <p>
      3、如果赛事取消，所有的注单将会被认为无效，除非注单在中断前已经结算或者在中断前已有明确的结果并且之后没有任何显著会影响赛事结果的情况注单才会被结算。
    </p>
    <p><br />单/双<br />1、赛事盘口包括全场、半场或单节投注。</p>
    <p>2、预测赛事的总比分是单数或双数。加时赛将包括在内。</p>
    <p><br />最先得分球队<br />1、预测最先得分的球队。</p>
    <p>2、如果赛事在有得分后中断，所有最先得分球队的注单将保持有效。</p>
    <p>3、如果赛事在没有球队得分前中断，所有最先得分球队的注单将被取消。</p>
    <p>
      4、如果赛事在4节完场时间以及加时赛内没有球队得分，所有最先得分球队的注单将被取消。
    </p>
    <p><br />最后得分球队<br />1、预测最后得分的球队。</p>
    <p>2、如果赛事中断， 所有最后得分球队的注单将被取消。</p>
    <p>
      3、如果赛事在4节完场时间以及加时赛内没有球队得分，所有最后得分球队的注单将被取消。
    </p>
    <p><br />每节最先获得20分的球队<br />1、预测每节最先得20分的球队。</p>
    <p>2、加时赛不计算在内。</p>
    <p>
      3、如果赛事中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况，注单才会被结算。若遇到任何其他情况，注单将一律被取消。
    </p>
    <p>
      4、取决于赛事，玩法指定球队需最先获得的分数可能有变化，并且会清楚的显示在盘口。
    </p>
    <p>5、如果每节都没有球队获得20分，所有注单将被取消。</p>
    <p><br />单节最高得分球队<br />1、预测单节最高得分的球队。</p>
    <p>2、如果赛事中断，所有单节最高得分球队的注单将被取消。</p>
    <p>3、加时赛不计算在内。</p>
    <p>
      4、如果赛事在4节完场时间以及加时赛内没有球队得分，所有最后单节最高得分球队的注单将被取消。
    </p>
    <p>
      <br />梦幻篮球游戏规则<br />1、梦幻篮球游戏将从同一天的赛事中任意选择两个球队（且原定不是在同一场赛事比赛的球队）进行投注。
    </p>
    <p>2、梦幻赛中的两支球队必须在同一天比赛，投注才被视为有效。</p>
    <p>
      3、梦幻比赛赛果会根据球队真实的比分为准；梦幻比赛的让分数则以球队真实比分来计算。
    </p>
    <p>
      4、如果球队的比赛时间和本公司网站显示的时间不同，所有涉及此球队的梦幻赛注单将被视为无效。
    </p>
    <p>5、梦幻赛投注将不考虑赛事实际进行的场地。</p>
    <p>
      6、梦幻赛的举例如下:<br />&nbsp; &nbsp; &nbsp;6.1、波士顿凯尔特人101 – 98
      芝加哥公牛，洛杉矶湖人118 – 101 奥兰多魔术<br />&nbsp; &nbsp;
      &nbsp;6.2、梦幻赛1： 波士顿凯尔特人vs 洛杉矶湖人<br />&nbsp; &nbsp;
      &nbsp;6.3、梦幻赛赛果：波士顿凯尔特人101 – 118洛杉矶湖人<br />&nbsp;
      &nbsp; &nbsp;6.4、梦幻赛的结算会根据各球队原定比赛的真实得分为准
    </p>
    <p>
      7、球队一定要实际完成原定比赛并且已在赛事的官方机构（例如：NBA）留下赛果记录，才能让涉及此球队的梦幻赛注单保持有效。如果球队没有完成原定比赛或最终赛果被官方否定，所有涉及此球队的梦幻赛注单将被取消。
    </p>
    <p>8、所有梦幻赛都将按照篮球个别玩法的规则和标准裁决。<br /></p>
  </div>
</template>
